// GLOBAL VARIABLES
// ===========================================================

// ==========[ GLOBALE KLEUR VOOR URLS ]==========
$global-link-color: #18bbb9;
$global-link-hover-color: #18bbb9;

// ==========[ GLOBALE KLEUREN ]==========
$global-background: #ffffff;
$global-primary-background: #18bbb9;
$global-success-background: #32d296;
$global-warning-background: #faa05a;
$global-danger-background: #f0506e;

// ==========[ DEFAULT FONT FAMILY ]==========
$global-font-family: 'sofia-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

// ==========[ GLOBAL MARGINS ]==========
$global-large-margin: 100px;
$global-medium-margin: 40px;
$global-small-margin: 10px;

// ==========[ GLOBAL BORDERS ]==========
$global-border-width: 1px;
$global-border: #e8e8e8;
$grid-divider-border: $global-border;

// ==========[ GRID ]==========

//GUTTER
$global-gutter: 60px;
$grid-gutter-horizontal: 60px;
$grid-gutter-vertical: 60px;
$global-large-gutter:60;
$grid-large-gutter-horizontal: 60px;
$grid-large-gutter-vertical: 60px;
$global-medium-gutter:60;
$grid-medium-gutter-horizontal: 20px;
$grid-medium-gutter-vertical: 40px;
$global-small-gutter: 60px;
$grid-small-gutter-horizontal: 20px;
$grid-small-gutter-vertical: 20px;


// OFF CANVAS NAV VARIABLES
// ===========================================================

// ==========[ OFFCANVAS NAV ACHTERGROND KLEUR ]==========
$offcanvas-bar-background: #fff;

// ==========[ KLEUR INHOUD VAN DE OFFCANVAS NAV (UK-LIGHT, UK-DARK, NONE) ]==========
$offcanvas-bar-color-mode: none;

// CONTAINERS
// ===========================================================

// ==========[ CONTAINER BREEDTES ]==========
$container-max-width: 1600px;
$container-xsmall-max-width: 750px;
$container-small-max-width: 900px;
$container-large-max-width: 1600px;
